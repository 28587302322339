import { ElementBuilder } from "../includes/_DOMutils";

class Dropdown extends HTMLElement {
	connectedCallback() {
		this.select = this.querySelector("select");
		this.select.classList.add("sr-only", "peer");
		this.select.tabIndex = -1;
		this.select.addEventListener("change", (e) => {
			if(this.selectedText.innerText != e.target.options[e.target.selectedIndex].text ) {
				// Value changed by external source, set the text
				this.selectedText.innerText = e.target.options[e.target.selectedIndex].text;
			}
		})

		this.label = null || this.querySelector("label");
		if (this.label) {
			this.label.classList.add("input-label");
		}
		this.createInput();
		if (this.select.disabled) {
			this.classList.add("text-grey");
		} else {
			this.createOptions();
			this.classList.add("cursor-pointer");
		}
		this.classList.add("relative", "w-full");
		let defaultOption = this.select.querySelector("option[selected]");
		let placeholder = this.select.getAttribute("placeholder");
		if (defaultOption && defaultOption.value) {
			this.selectedText.innerText = defaultOption.innerText;
			this.select.value = defaultOption.value;
			this.inputContainer.classList.add("border-navy");
		} else if (placeholder) {
			this.selectedText.innerText = placeholder;
		}
	}

	createInput() {
		this.inputContainer = this.querySelector(".input-container");
		if (!this.inputContainer) {
			this.inputContainer = new ElementBuilder("div")
				.classes("input-container", "focus-within:border-blue")
				.tabIndex(0)
				.addEventListener("click", (e) => this.showDropdown(e))
				.addEventListener("keyup", (e) => this.showDropdown(e))
				.addEventListener("blur", () => this.hideDropdown())
				.child(
					new ElementBuilder("div")
						.classes(
							"w-full",
							"focus:outline-none",
							"placeholder-black",
							"text-sm",
							"sm:text-base"
						)
						.setAttribute("data-selected").build
				)
				.child(
					new ElementBuilder("div")
						.classes("dropdown-arrow")
						.innerHtml(
							`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"></path></svg>`
						).build
				)
				.parent(this).build;
		}
		this.selectedText = this.inputContainer.querySelector("[data-selected]");
	}

	createOptions() {
		this.optionsContainer = document.querySelector("options-container");
		if (!this.optionsContainer) {
			let fragment = document.createDocumentFragment();
			this.optionsContainer = new ElementBuilder("div")
				.classes("options-container", "hidden")
				.parent(fragment)
				.top(`26px`)
				.child(
					new ElementBuilder("div") //spacer
						.height(`35px`).build
				).build;

			this.optionsContainerInner = new ElementBuilder("div")
				.classes("options-container-inner")
				.parent(this.optionsContainer).build;

			Array.from(this.select.options).forEach((option) => {
				new ElementBuilder("div")
					.classes("input-item")
					.innerText(option.text)
					.tabIndex(0)
					.setAttribute("data-value", option.value)
					.parent(this.optionsContainerInner)
					.addEventListener("blur", () => this.hideDropdown())
					.addEventListener("click", (e) => this.hideDropdown(e))
					.addEventListener("keyup", (e) => this.hideDropdown(e))
					.addEventListener("click", (e) => this.selectOption(e))
					.addEventListener("keypress", (e) => this.selectOption(e)).build;
			});
			this.append(fragment);
		}
	}

	showDropdown(e) {
		if (this.select.disabled) {
			return;
		}
		if ((e && e.key === "Enter") || (e && e.type === "click")) {
			this.classList.add("open");
			this.optionsContainer.classList.remove("hidden");
			if (this.label) {
				this.label.classList.add("text-blue");
			}
			this.querySelector(".input-container").classList.remove("border-navy");
			this.querySelector(".input-container").classList.add("border-blue");
			this.inputContainer.querySelector("svg").classList.add("rotate-180");
			this.querySelector("svg").classList.add("text-blue");
		}
	}

	hideDropdown(e) {
		if (this.select.disabled) {
			return;
		}
		if ((e && e.key === "Enter") || (e && e.type === "click")) {
			e.target.blur();
		}
		setTimeout(() => {
			if (this.optionsContainer.contains(document.activeElement)) {
				return;
			}
			this.classList.remove("open");
			this.optionsContainer.classList.add("hidden");
			if (this.label) {
				this.label.classList.remove("text-blue");
			}
			this.querySelector(".input-container").classList.remove("border-blue");
			if (this.select.value !== "") {
				this.querySelector(".input-container").classList.add("border-navy");
			}
			this.inputContainer.querySelector("svg").classList.remove("rotate-180");
			this.querySelector("svg").classList.remove("text-blue");
		});
	}

	selectOption(e) {
		if (!e.target) return;
		if ((e && e.key === "Enter") || (e && e.type === "click")) {
			this.selectedText.innerText = e.target.innerText;
			this.select.value = e.target.getAttribute("data-value");
			const event = new Event("change");
			this.select.dispatchEvent(event);
		}
	}
}

customElements.define("custom-dropdown", Dropdown);
