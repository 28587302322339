class SearchSelect extends HTMLElement {
	// TODO combine with SearchSelectMultiple
	connectedCallback() {
		this.select = this.querySelector("select");
		this.select.classList.add("sr-only");
		this.options = Array.from(this.select.querySelectorAll("option"));

		this.classList.add("relative");

		const container = document.createElement("div");

		const input = document.createElement("input");
		input.type = "search";
		input.placeholder = this.getAttribute("placeholder");
		input.addEventListener("keyup", (e) => {
			this.search(e.target.value);
			this.isScrollbarVisible();
		});
		input.classList.add("search-select-input", "focus:border-blue");
		this.input = input;
		container.appendChild(input);

		if (this.select.value) {
			const text = this.options.find(
				(o) => o.value === this.select.value
			).innerText;
			this.input.value = text;
		}

		this.resultsContainer = document.createElement("div");
		this.resultsContainer.classList.add("results-container");
		container.appendChild(this.resultsContainer);
		this.appendChild(container);
	}

	search(value) {
		this.resultsContainer.innerHTML = "";
		if (value.length < 3) {
			return;
		}
		const matchRegex = new RegExp(value, "i");
		let matchCount = 0;
		this.options.forEach((o) => {
			if (o.text.match(matchRegex)) {
				this.addResult(o);
				matchCount++;
			}
		});
		if (matchCount == 0) {
			this.renderNoResults();
		}
	}

	onActionPress(resultNode) {
		this.input.value = resultNode.getAttribute("data-display");
		this.resultsContainer.innerHTML = "";
		this.select.value = resultNode.getAttribute("data-value");
		const event = new Event("change");
		this.select.dispatchEvent(event);
		this.querySelector(".results-container").classList.remove(
			"scrollbar-visible"
		);
	}

	addResult(result) {
		let resultNode = document.createElement("div");
		resultNode.innerText = result.innerText;
		resultNode.setAttribute("data-display", result.innerText);
		resultNode.setAttribute("data-value", result.value);
		resultNode.classList.add("result-node");
		resultNode.addEventListener("click", (e) => {
			e.preventDefault();
			this.onActionPress(resultNode);
		});
		this.resultsContainer.appendChild(resultNode);
	}

	renderNoResults() {
		let noResultsNode = document.createElement("div");
		noResultsNode.innerText = "No matches found";
		noResultsNode.classList.add("result-node", "no-results");
		this.resultsContainer.appendChild(noResultsNode);
	}

	close() {
		this.resultsContainer.innerHTML = "";
	}

	isScrollbarVisible() {
		this.querySelector(".results-container").classList.toggle(
			"scrollbar-visible",
			this.resultsContainer.scrollHeight > this.resultsContainer.clientHeight
		);
	}
}

customElements.define("search-select", SearchSelect);
