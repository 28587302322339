import { ElementBuilder } from "../includes/_DOMutils";

class SearchSelectMultiple extends HTMLElement {
	connectedCallback() {
		const select = this.querySelector("select");
		select.classList.add("sr-only");
		const heading = this.getAttribute("heading");
		this.options = Array.from(select.querySelectorAll("option"));

		this.classList.add("relative"); // for label positioning
		this.container = new ElementBuilder("div")
			.classes("relative", "flex-col-10")
			.child(
				new ElementBuilder("label")
					.innerText(`Add ${heading}`)
					.classes("search-select-label").build
			).build;

		let debounceTimer = null;
		this.input = new ElementBuilder("input")
			.type("search")
			.placeholder(this.getAttribute("placeholder"))
			.addEventListener("keyup", (e) => {
				clearTimeout(debounceTimer);
				debounceTimer = setTimeout(() => {
					this.search(e.target.value);
				}, 300);
			})
			.addEventListener("keypress", (e) => {
				if (e.keyCode === 13) {
					e.preventDefault();
				}
			})
			.classes("search-select-input", "focus:border-blue")
			.parent(this.container).build;

		this.resultsContainer = new ElementBuilder("div")
			.parent(this.container)
			.classes("results-container", "hidden").build;

		this.selectedContainer = new ElementBuilder("div")
			.classes("selected-container", "relative", "transition-opacity")
			.child(
				new ElementBuilder("h2")
					.innerText(heading)
					.classes("search-select-label").build
			)
			.child(new ElementBuilder("ul").build).build;

		this.selectedShowing = true;
		if (!select.value) {
			this.selectedShowing = false;
			this.selectedContainer.classList.add("hidden", "opacity-0");
		}

		this.selectedOptions = this.selectedContainer.querySelector("ul");
		this.parentElement.appendChild(this.selectedContainer);

		this.options.forEach((option) => {
			if (option.selected || option.getAttribute("selected") == "") {
				this.selectOption(option);
			}
		});
		this.appendChild(this.container);

		let _this = this;
		this.handleClickOutsideBound = this.handleClickOutside.bind(this);

		this.input.addEventListener("focus", () => {
			this.style.zIndex = 50;
			this.search(this.input.value);
			document.addEventListener("mousedown", _this.handleClickOutsideBound);
		});
	}

	removeClickOutsideListener() {
		document.removeEventListener("mousedown", this.handleClickOutsideBound);
	}

	handleClickOutside(e) {
		let _this = this;
		if (!this.container.contains(e.target)) {
			this.style.zIndex = 0;
			this.resultsContainer.innerHTML = "";
			_this.removeClickOutsideListener();
		}
	}

	search(value) {
		this.resultsContainer.innerHTML = "";
		this.resultsContainer.classList.remove("hidden");
		let resultsFound = false;
		if (this.attributes.endpoint) {
			fetch(`${this.getAttribute("endpoint")}?q=${encodeURIComponent(value)}`, {
				method: "GET",
			})
				.then((res) => res.json())
				.then((res) => {
					res.forEach((result) => {
						const option = this.options.find(
							(o) => o.value == result.id && !o.selected
						);
						if (option) {
							this.addResult(option);
							resultsFound = true;
						}
					});
					if (!resultsFound) {
						this.noResults(value);
					}
				});
		} else {
			const matchRegex = new RegExp(value, "i");
			this.options.forEach((o) => {
				if (!o.selected && o.text.match(matchRegex)) {
					resultsFound = true;
					this.addResult(o);
				}
			});
			if (!resultsFound) {
				this.noResults(value);
			}
		}
	}

	noResults(value) {
		let noResultsMessage = new ElementBuilder("div")
			.child(
				new ElementBuilder("span").innerText(`No results found for "${value}"`)
					.build
			)
			.classes("result-node", "no-results").build;
		this.resultsContainer.appendChild(noResultsMessage);
	}

	addResult(result) {
		let resultNode = new ElementBuilder("div")
			.child(new ElementBuilder("span").innerText(result.innerText).build)
			.addEventListener("click", (e) => {
				this.selectOption(result);
			})
			.classes("result-node").build;
		this.resultsContainer.appendChild(resultNode);
	}

	selectOption(option) {
		option.selected = true;
		this.resultsContainer.innerHTML = "";
		const removeHandler = (e) => {
			e.preventDefault();
			option.selected = false;
			this.removeOption(option.value);
		};
		this.addResultRow(option.value, option.innerText, removeHandler);
		this.removeClickOutsideListener();
	}

	addResultRow(value, text, buttonHandler) {
		let row = new ElementBuilder("li")
			.setAttribute("data-value", value)
			.classes("selected-node")
			.child(new ElementBuilder("span").innerText(text).build)
			.child(
				new ElementBuilder("button")
					.innerText("Remove")
					.classes("uppercase", "text-blue")
					.addEventListener("click", buttonHandler).build
			).build;
		this.selectedOptions.appendChild(row);

		if (!this.selectedShowing) {
			this.selectedContainer.classList.remove("hidden");
			this.selectedContainer.classList.remove("opacity-0");
		}
	}

	removeOption(value) {
		this.selectedOptions.querySelector(`[data-value="${value}"]`).remove();
	}
}

customElements.define("search-select-multiple", SearchSelectMultiple);
